<template>
  <v-card
    v-if="usersList.users"
    class="elevation-0 transparent rounded-lg"
    :disabled="loading"
  >
    <v-toolbar
      color="white"
      class=" mb-3 rounded-lg"
      style="box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);"
    >
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        single-line
        solo
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search') + ' users'"
        style="width:100%"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-select
        flat
        hide-details
        rounded
        dense
        color="primary"
        background-color="blue-grey lighten-5"
        height="36px"
        :items="keys"
        single-line
        :label="'Status'"
        v-model="sortBy"
        class="mx-2"
        style="width: 200px !important;"
      ></v-select>
      <v-btn @click="createUser = true" color="primary" elevation="0" rounded>
        <v-icon left dark class="mr-3"> mdi-plus </v-icon>{{ $t("MF.add") }}
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items.sync="searchresult"
      :loading="loading"
      flat
      :locale="this.$root.lang"
      @click:row="openuser"
      class="rounded-lg"
    >
      <!-- body -->
      <template v-slot:item.timezone="{ item }">
        <div class="font-size-md">{{ item.timezone }}</div>
      </template>
      <template v-slot:item.avatar="{ item }">
        <v-list-item>
          <v-badge
            bordered
            top
            :color="setColor(item.status)"
            dot
            offset-x="10"
            offset-y="10"
          >
            <v-avatar color="primary" size="40">
              <v-img
                v-if="item.avatar != null"
                :src="item.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.first_name.charAt(0) }}
              </div>
            </v-avatar>
          </v-badge>
          <v-list-item-content class="mx-3">
            <v-list-item-title>
              {{ item.first_name }} {{ item.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.status }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:item.email="{ item }">
        <div class="font-size-md">{{ item.email }}</div>
      </template>
      <template v-slot:item.phone="{ item }">
        <div class="font-size-md">{{ item.phone }}</div>
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model="doOpenUser"
      app
      temporary
      width="50vw"
      style="border-radius: 0;"
      :right="$root.lang != 'ar'"
    >
      <UserPage
        ref="userside"
        @close="doOpenUser = false"
        
      />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="createUser"
      app
      temporary
      width="50vw"
      style="border-radius: 0;"
      :right="$root.lang != 'ar'"
    >
    <keep-alive>
      <NewUser @update="showupdate" ref="adduser" :isActive="createUser" :role="$root.admin.selectedRole" @close="createUser = false"/>
    </keep-alive>
    </v-navigation-drawer>
    <v-snackbar timeout="5000" v-model="snackbar" absolute bottom >
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import UserPage from "./comp/UserPage.vue";
import NewUser from "./comp/UserForm.vue";
export default {
  components: { UserPage, NewUser },
  data() {
    return {
      loading: true,
      dialogDelete: false,
      headers: [
        { text: "User", value: "avatar", sortable: false },
        { text: "Timezone", value: "timezone" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone", sortable: false },
      ],

      usersList: [],
      formTitle: "Add New User",
      user: null,
      openEditDialog: false,
      openAddDialog: false,
      search: "",
      sortBy: "",
      keys: ["All", "Active", "Suspended", "Draft"],
      doOpenUser: false,
      createUser:false,
      langs: null,
      snackbar:false,
      msg:"",
    };
  },

  created() {
    this.langs = this.$util.getLanguages();
    this.$root.title = "Manage Users";
    this.$root.admin.extramargin = "390px";
    this.$root.admin.openside = true;
    this.$root.currentpage = "users";
  },
  watch: {
    "$root.admin": {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
    doOpenUser(flag) {
      if (!flag) {
        this.$refs.userside.clearUser();
      }
    },
    createUser(flag) {
      if (!flag) {
        this.$refs.adduser.reset();
      }
    },
    
  },

  methods: {
    stayopen() {
      console.log("clicked outside");
      if (this.doOpenUser) this.doOpenUser = true;
    },
    showupdate(m)
    {
      this.snackbar = true;
      this.msg = m;
    },
    openuser(e) {
      //console.log("e",e);

      this.$refs.userside.setUser(e);
      this.doOpenUser = true;
    },
    closeuser() {
      this.doOpenUser = false;
    },
    async getUsers() {
      try {
        this.loading = true;
        this.usersList = await this.$user.getUsersByRole(
          this.$root.admin.selectedRole,
          true
        );
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    replaceinlist(u) {
      console.log("resetting to this", u);
      console.log("before edit userlist", this.usersList);
      var i = this.usersList.users.findIndex((item) => {
        return item.id == u.id;
      });
      this.usersList.users[i] = u;
      console.log("after edit userlist", this.usersList);
    },
    editItem(item) {
      console.log(item);
      this.user = item;
      this.formTitle = "Edit User";
      this.openEditDialog = true;
    },

    deleteItem(user) {
      this.user = user;
      this.dialogDelete = true;
    },
    setColor(status) {
      switch (status) {
        case "active": {
          return "success";
        }
        case "suspended": {
          return "red";
        }
        case "draft": {
          return "warning";
        }
      }
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        if (this.user.role.id == "3") {
          const doctor = await ApiService.get(
            `items/doctor?fields=id,practice_started,clinic,video,department,bio&filter[doctor_id]=${this.user.id}`
          );
          const docAvails = await ApiService.get(
            `items/doctor_availability?fields=id&filter[doctor]=${doctor.data.data[0].id}`
          );

          await ApiService.delete(
            "items/doctor_availability",
            docAvails.data.data[0].id
          );

          await ApiService.update(
            "items/doctor_availability",
            doctor.data.data[0].id,
            { status: "deleted" }
          );

          await ApiService.update("users", this.user.id, {
            status: "deleted",
          });
        }

        if (this.user.role.id == "4") {
          await ApiService.update("items/medical_file", this.user.id, {
            status: "deleted",
          });

          await ApiService.update("users", this.user.id, {
            status: "deleted",
          });
        }

        this.loading = false;
        this.closeDelete();
        this.user = null;
        this.toast(
          "b-toaster-top-center",
          "success",
          "user deleted successfully"
        );
        this.getUsers();
      } catch (error) {
        console.log(error);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    formSubmitted(msg) {
      this.openEditDialog = false;
      this.openAddDialog = false;
      this.toast("b-toaster-top-center", "success", msg);
      this.getUsers();
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append,
      });
    },
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    usersSorted() {
      return this.usersList.users.filter((i) => {
        return (
          !this.sortBy ||
          i.status.toLowerCase() === this.sortBy.toLowerCase() ||
          this.sortBy == "All"
        );
      });
    },
    searchresult() {
      return this.usersSorted.filter((i) => {
        return (
          !this.search ||
          i.first_name.toLowerCase().includes(this.search) ||
          i.last_name.toLowerCase().includes(this.search) ||
          i.email.includes(this.search) ||
          (i.phone != null && i.phone.includes(this.search))
        );
      });
    },
  },
};
</script>

<style></style>
