<template>
  <v-card
    elevation="0"
    v-if="user != null && !$root.mobile"
    class="transparent"
  >
    <v-toolbar
      v-if="!isprofilepage"
      color="rgb(238, 240, 248)"
      style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
      height="60"
      extension-height="50px"
    >
      <v-btn icon dark color="primary" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="text-primary">
        {{ user.first_name }} {{ user.last_name }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        class="mx-2"
        small
        :rounded="doedit"
        :fab="!doedit"
        height="40px"
        elevation="0"
        v-if="false"
        color="rgba(165, 203, 239, 0.20)"
        @click="doedit = !doedit"
      >
        <unicon
          style="margin-right: auto; margin-left: auto"
          name="edit-alt"
          fill="#0971CE"
          height="20"
        ></unicon>
        <span class="text-primary" v-if="doedit">editing active</span>
      </v-btn>
      <v-btn
        v-if="user.status != 'suspended'"
        small
        elevation="0"
        :loading="statuschangeloading"
        :disabled="statuschangeloading"
        @click="updateStatus('suspended')"
        rounded
        height="40px"
        color="rgba(239, 165, 179, 0.25)"
      >
        <unicon
          style="margin-right: auto; margin-left: auto"
          name="pause-circle"
          fill="#f64e60"
          height="20"
        ></unicon>
        <span style="color: #f64e60">Suspend Account</span>
      </v-btn>
      <v-btn
        v-else
        small
        elevation="0"
        :loading="statuschangeloading"
        :disabled="statuschangeloading"
        @click="updateStatus('active')"
        rounded
        height="40px"
        color="rgba(165, 203, 239, 0.20)"
      >
        <unicon
          style="margin-right: auto; margin-left: auto"
          name="check-circle"
          fill="#0971CE"
          height="20"
        ></unicon>
        <span style="color: #0971ce">Activate Account</span>
      </v-btn>
      <template v-slot:extension>
        <v-tabs v-if="!isprofilepage" v-model="tab" grow>
          <v-tab v-for="tabitem in tabs"
          :key="tabitem.tab">
            <unicon
              :name="tabitem.icon"
              class="pushxl"
              fill="#0971ce"
              height="30"
            ></unicon
            >{{tabitem.title}}</v-tab
          >
          
        </v-tabs>
      </template>
    </v-toolbar>
    <perfect-scrollbar
      class="scroll"
      style="position: relative"
      :style="scrollheight"
    >
      <v-card-text class="p-0 m-0">
        <v-row class="m-0 p-0">
          <v-col
            v-if="isprofilepage"
            class="m-0 p-0"
            :class="isprofilepage ? 'px-3' : ''"
            :cols="isprofilepage ? '3' : '12'"
          >
            <v-list-item
              three-line
              class="listitem p-1 mb-3 px-2 white"
              :style="isprofilepage ? '' : 'border-radius: 0 0 10px 10px; '"
            >
              <v-badge
                bordered
                top
                :color="setColor(user.status)"
                dot
                offset-x="15"
                offset-y="15"
              >
                <v-list-item-avatar
                  style="margin-right: auto; margin-left: auto"
                  class="m-0"
                  color="primary"
                  size="70"
                >
                  <v-avatar color="primary" size="70">
                    <v-img
                      v-if="user.avatar != null"
                      :src="$root.apiURL + 'assets/' + user.avatar.private_hash"
                    ></v-img>
                    <div
                      v-else
                      style="text-transform: uppercase; width: 100%"
                      class="white--text font-size-h1"
                    >
                      {{ user.first_name.charAt(0) }}
                    </div>
                  </v-avatar>
                </v-list-item-avatar>
              </v-badge>
              <v-list-item-content class="mx-3">
                <v-list-item-title style="font-size: 1.3rem">
                  {{ user.first_name }} {{ user.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>Status: {{ user.status }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Last Access:
                  {{
                    user.last_access_on
                      | moment(
                        "timezone",
                        $util.getTimezone(),
                        "ddd DD/MM/YYYY hh:mm A"
                      )
                  }},
                  {{
                    user.last_access_on
                      | moment("timezone", $util.getTimezone(), "from", "now")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-tabs
              active-class="activetab"
              hide-slider
              v-if="isprofilepage"
              class="transparent"
              left
              vertical
              v-model="tab"
            >
              <v-tab
              v-for="tabitem in tabs"
          :key="tabitem.tab"
                class="listitem mb-2"
                style="color: #0971ce; min-height: 0px"
                @click="tabchange('account')"
              >
                <unicon
                  :name="tabitem.icon"
                  class="pushxl"
                  fill="#0971ce"
                  height="30"
                ></unicon
                >{{tabitem.title}}</v-tab
              >
              
            </v-tabs>
            <div
              @click="onLogout"
              class="mb-2 red mt-5 p-5"
              style="
                cursor: pointer;
                min-height: 0px;
                height: 48px;
                display: flex;
                align-items: center;
                border-radius: 16px;
                letter-spacing: 0.0892857143em;
              "
            >
              <unicon
                name="sign-out-alt"
                class="pushxl"
                fill="#ffffff"
                height="30"
              ></unicon
              ><span
                style="
                  color: #ffffff;
                  font-size: 0.875rem;
                  font-weight: 500;
                  text-transform: uppercase;
                "
                >{{ $t("SIDE.signout") }}</span
              >
            </div>
          </v-col>
          <v-col
            class="m-0 p-0 white rounded-lg"
            :cols="isprofilepage ? '9' : '12'"
          >
            <v-tabs-items v-model="tab" class="p-7">
              <!-- account -->
              <v-tab-item>
                <keep-alive>
                  <Account
                    v-if="currenttab == 'account'"
                    ref="useraccount"
                    :propuser="user"
                  />
                </keep-alive>
              </v-tab-item>
              <!-- file -->
              <v-tab-item v-if="user.role.id == 4">
                <keep-alive>
                  <MedicalProfile
                    v-if="currenttab == 'mf'"
                    ref="mfprofile"
                    :uid="user.id"
                  />
                </keep-alive>
              </v-tab-item>
              <!-- Doctor Practice and profile -->
              <v-tab-item v-if="user.role.id == 3">
                <keep-alive>
                  <DoctorProfile
                    v-if="currenttab == 'practice'"
                    ref="drprofile"
                    :uid="user.id"
                  />
                </keep-alive>
              </v-tab-item>
              <!-- Notification -->
              <v-tab-item>
                <keep-alive>
                  <NotificationsProfile
                    v-if="currenttab == 'notify'"
                    ref="notify"
                    :uid="user.id"
                  />
                </keep-alive>
              </v-tab-item>
              <!-- Patient Payments -->
              <v-tab-item v-if="user.role.id == 4 && isPaymentEnabled">
                <keep-alive>
                  <UserPayments
                    v-if="currenttab == 'payment'"
                    ref="payment"
                    :uid="user.id"
                  />
                </keep-alive>
              </v-tab-item>
              <!-- Doctor Errnings -->
              <v-tab-item v-if="user.role.id == 3 && isPaymentEnabled">
                <keep-alive>
                  <DoctorEarning
                    v-if="currenttab == 'errning'"
                    ref="payment"
                    :uid="user.id"
                  />
                </keep-alive>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </perfect-scrollbar>
  </v-card>
  <v-card
    v-else-if="user != null && $root.mobile"
    elevation="0"
    class="transparent"
  >
    <v-list-item class="p-0 mb-3">
      <v-badge
        bordered
        top
        :color="setColor(user.status)"
        dot
        offset-x="13"
        offset-y="13"
      >
        <v-list-item-avatar
          style="margin-right: auto; margin-left: auto"
          class="m-0"
          color="primary"
          size="60"
        >
          <v-avatar color="primary" size="60">
            <v-img
              v-if="user.avatar != null"
              :src="$root.apiURL + 'assets/' + user.avatar.private_hash"
            ></v-img>
            <div
              v-else
              style="text-transform: uppercase; width: 100%"
              class="white--text font-size-h1"
            >
              {{ user.first_name.charAt(0) }}
            </div>
          </v-avatar>
        </v-list-item-avatar>
      </v-badge>
      <v-list-item-content class="mx-3">
        <v-list-item-title style="font-size: 1.4rem" class="">
          {{ user.first_name }} {{ user.last_name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Last Access:
          {{
            user.last_access_on
              | moment(
                "timezone",
                $util.getTimezone(),
                "ddd DD/MM/YYYY hh:mm A"
              )
          }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{
            user.last_access_on
              | moment("timezone", $util.getTimezone(), "from", "now")
          }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list class="transparent">
      <v-list-item-group v-model="tab">
        <v-list-item
          v-for="tabitem in tabs"
          :key="tabitem.tab"
          class="listitem mb-2"
        >
          <v-list-item-avatar>
            <unicon :name="tabitem.icon" fill="#0971ce" height="30"></unicon>
          </v-list-item-avatar>
          <v-list-item-content style="font-size: 1.3rem" class="primary--text">
            {{ tabitem.title }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item @click="onLogout" class="red listitem mb-2 mt-6">
        <v-list-item-avatar>
          <unicon name="sign-out-alt" fill="#ffffff" height="30"></unicon>
        </v-list-item-avatar>
        <v-list-item-content style="font-size: 1.3rem" class="white--text">
          {{ $t("SIDE.signout") }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="mobiledialog" fullscreen>
      <v-toolbar
        color="rgb(238, 240, 248)"
        style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
        height="60"
      >
        <v-btn
          icon
          dark
          color="primary"
          @click="
            mobiledialog = false;
            tab = null;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-primary">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <perfect-scrollbar
        class="scroll"
        style="height: calc(100vh - 60px); position: relative"
      >
        <v-card style="min-height: calc(100vh - 60px)" flat class="m-0 p-0">
          <v-tabs-items v-model="tab" class="p-7">
            <!-- account -->
            <v-tab-item>
              <keep-alive>
                <Account
                  v-if="currenttab == 'account'"
                  ref="useraccount"
                  :propuser="user"
                />
              </keep-alive>
            </v-tab-item>
            <!-- file -->
            <v-tab-item v-if="user.role.id == 4">
              <keep-alive>
                <MedicalProfile
                  v-if="currenttab == 'mf'"
                  ref="mfprofile"
                  :uid="user.id"
                />
              </keep-alive>
            </v-tab-item>
            <!-- Doctor Practice and profile -->
            <v-tab-item v-if="user.role.id == 3">
              <keep-alive>
                <DoctorProfile
                  v-if="currenttab == 'practice'"
                  ref="drprofile"
                  :uid="user.id"
                />
              </keep-alive>
            </v-tab-item>
            <!-- Notification -->
            <v-tab-item>
              <keep-alive>
                <NotificationsProfile
                  v-if="currenttab == 'notify'"
                  ref="notify"
                  :uid="user.id"
                />
              </keep-alive>
            </v-tab-item>
            <!-- Patient Payments -->
            <v-tab-item v-if="user.role.id == 4 && isPaymentEnabled">
              <keep-alive>
                <UserPayments
                  v-if="currenttab == 'payment'"
                  ref="payment"
                  :uid="user.id"
                />
              </keep-alive>
            </v-tab-item>
            <!-- Doctor Errnings -->
            <v-tab-item v-if="user.role.id == 3 && isPaymentEnabled">
              <keep-alive>
                <DoctorEarning
                  v-if="currenttab == 'errning'"
                  ref="payment"
                  :uid="user.id"
                />
              </keep-alive>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </perfect-scrollbar>
    </v-dialog>
  </v-card>
</template>

<script>
import Account from "./usercomp/account.vue";
import { LOGOUT } from "@/core/services/store/auth.module";
import { mapActions } from "vuex";

export default {
  components: {
    Account,
    MedicalProfile: () => import("./usercomp/medical.vue"),
    DoctorProfile: () => import("./usercomp/doctor.vue"),
    NotificationsProfile: () => import("./usercomp/notification.vue"),
    UserPayments: () => import("./usercomp/payments.vue"),
    DoctorEarning: () => import("./usercomp/earning.vue"),
  },
  name: "UserPage",
  data() {
    return {
      isprofilepage: false,
      loading: true,
      user: null,
      tab: null,
      currenttab: "account",
      doedit: true,
      isActive: true,
      updated: false,
      avataraction: 0,
      notificationDevices: null,
      payments: null,
      doctor: null,
      departments: null,
      langs: null,
      mf: null,
      errnings: null,
      mobiledialog: false,
      statuschangeloading: false,
      doctortab: [
        { tab: "account", title: "Account Information", icon: "user-circle" },
        {
          tab: "practice",
          title: "Medical Practice & Profile",
          icon: "hospital",
        },
        { tab: "notify", title: "Notifications", icon: "bell" },
        { tab: "errning", title: "Earnings", icon: "pound-circle" },
      ],
      pateinttabs: [
        { tab: "account", title: "Account Information", icon: "user-circle" },
        { tab: "mf", title: "Medical File Information", icon: "hospital" },
        { tab: "notify", title: "Notifications", icon: "bell" },
        {
          tab: "payment",
          title: "Payment History",
          icon: "credit-card",
        },
      ],
      usertabs: [
        { tab: "account", title: "Account Information", icon: "user-circle" },
        { tab: "notify", title: "Notifications", icon: "bell" },
      ],
      tabs: [],
      title: "Account Info",
    };
  },

  created() {
    this.langs = this.$util.getLanguages();
  },
  watch: {
    isActive: function (flag) {
      if (flag) {
        this.user.status = "active";
      } else {
        this.user.status = "suspended";
      }
    },
    tab: function (newtab) {
      if (newtab != null) {
        this.tabchange(this.tabs[newtab].tab);
        this.title = this.tabs[newtab].title;
        this.mobiledialog = true;
      }
    },
  },

  methods: {
    ...mapActions(["login"]),
    onLogout() {
      this.$root.title = "Doctory";
      this.$root.mf = 0;
      this.$root.docID = 0;
      this.$root.viaid = 0;
      this.$root.doctormf = 0;
      this.$root.viaid = 0;
      this.$root.viaid = 0;
      this.$root.admin = {
        extramargin: "90px",
        selectedRole: 1,
        openside: false,
        currentuser: null,
        selectedSetting: "system",
      };
      this.$user.clearData();
      this.$doctor.clearData();
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    async updateStatus(stat) {
      this.statuschangeloading = true;
      await this.$user
        .updateUser(this.user.id, { status: stat })
        .then((data) => {
          this.statuschangeloading = false;
          console.log("updated status", data.data.data.status);
          this.user.status = data.data.data.status;
        });
    },
    tabchange(tabname) {
      this.currenttab = tabname;
    },

    setUser(u, isprofile = false) {
      this.currenttab = "account";
      if (u.preferred_lang) {
        console.log("setting lang");
        let tempLang = this.langs.find((l) => {
          return l.id == u.preferred_lang;
        });
        u.preferred_lang_obj = tempLang;
      }
      console.log("setting user", u);
      if (isprofile) {
        this.isprofilepage = isprofile;
      }
      this.user = u;
      if (u.role.id == 3) {
        this.tabs = this.doctortab;
      } else if (u.role.id == 4) {
        this.tabs = this.pateinttabs;
      } else {
        this.tabs = this.usertabs;
      }
      console.log("tabs are", this.tabs)
      this.$user.getLastAccess(u.id).then((data) => {
        this.user.last_access_on = data.created_on;
      });
    },
    setColor(status) {
      switch (status) {
        case "active": {
          return "success";
        }
        case "suspended": {
          return "red";
        }
        case "draft": {
          return "warning";
        }
      }
    },
    close() {
      this.$emit("close");
      this.clearUser();
    },
    clearUser() {
      this.currenttab = "";
      this.user = null;
      (this.notificationDevices = null),
        (this.tab = 0),
        (this.payments = null),
        (this.mf = null),
        (this.doctor = null),
        (this.errnings = null);
    },
  },
  async mounted() {
    this.departments = await this.$department.getDepartments();
  },
  computed: {
    scrollheight() {
      if (this.isprofilepage) return "height:auto;";
      else return "height:calc(100vh - 110px);";
    },
    isPaymentEnabled() {
      console.log(
        "settings",
        this.$util.getSettings(),
        "payment is",
        this.$util.getSettings()["payment"]
      );
      return this.$util.getSettings()["payment"];
    },
  },
};
</script>
<style>
.circles-to-rhombuses-spinner,
.circles-to-rhombuses-spinner * {
  box-sizing: border-box;
}

.circles-to-rhombuses-spinner {
  height: 15px;
  width: calc((15px + 15px * 1.125) * 3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circles-to-rhombuses-spinner .circle {
  height: 15px;
  width: 15px;
  margin-left: calc(15px * 1.125);
  transform: rotate(45deg);
  border-radius: 10%;
  border: 3px solid #0971ce;
  overflow: hidden;
  background: transparent;

  animation: circles-to-rhombuses-animation 1200ms linear infinite;
}

.circles-to-rhombuses-spinner .circle:nth-child(1) {
  animation-delay: calc(150ms * 1);
  margin-left: 0;
}

.circles-to-rhombuses-spinner .circle:nth-child(2) {
  animation-delay: calc(150ms * 2);
}

.circles-to-rhombuses-spinner .circle:nth-child(3) {
  animation-delay: calc(150ms * 3);
}

@keyframes circles-to-rhombuses-animation {
  0% {
    border-radius: 10%;
  }

  17.5% {
    border-radius: 10%;
  }

  50% {
    border-radius: 100%;
  }

  93.5% {
    border-radius: 10%;
  }

  100% {
    border-radius: 10%;
  }
}

@keyframes circles-to-rhombuses-background-animation {
  50% {
    opacity: 0.4;
  }
}
.profilebg {
  background: linear-gradient(
    319deg,
    rgba(9, 114, 206, 0.3),
    rgba(183, 28, 28, 0.3),
    rgba(255, 179, 0, 0.3)
  );
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 4%;
  }
  50% {
    background-position: 100% 97%;
  }
  100% {
    background-position: 0% 4%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 4%;
  }
  50% {
    background-position: 100% 97%;
  }
  100% {
    background-position: 0% 4%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 4%;
  }
  50% {
    background-position: 100% 97%;
  }
  100% {
    background-position: 0% 4%;
  }
}

.activetab {
  background: #0971ce;
  color: white !important;
}
.activetab .unicon svg {
  fill: white;
}
</style>
<style scoped>
.theme--light >>> .v-input--is-disabled input {
  color: black;
}

.theme--light >>> .v-input--is--disabled textarea {
  color: black;
}

.theme--light >>> .v-select .v-select__selection--disabled {
  color: black;
}
.theme--light >>> .v-input__append-inner .v-icon.v-icon.v-icon--disabled {
  color: transparent !important;
}
.v-tabs--vertical >>> .v-tabs-bar .v-tab {
  display: flex;
  justify-content: flex-start;
}
.theme--light.v-tabs >>> .v-tabs-bar {
  background-color: transparent;
}
.theme--light.v-tabs:hover::before {
  border-radius: 16px !important;
}
.theme--light.v-list-item:hover::before {
  border-radius: 16px !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  border-radius: 16px !important;
}
</style>
